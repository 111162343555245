export const clickOutSide = {
    mounted: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value(event, el)
            }
        }
        document.addEventListener("click", el.clickOutsideEvent)
    },
    unmounted: function (el) {
        document.removeEventListener("click", el.clickOutsideEvent)
    },
    getSSRProps(binding) {
        // server-side implementation:
        // return the props to be rendered.
        // getSSRProps only receives the directive binding.
        return {
            id: binding.value
        }
    }
}

export default clickOutSide
